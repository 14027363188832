import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withStyles, makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CardMedia from '@mui/material/CardMedia';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import GetAppIcon from '@mui/icons-material/GetApp';
import imgAxa from '../static/images/axa.png';
import TaskForm from '../common/TaskForm';
import CommentForm from '../common/CommentForm';
import MessageFormChat from '../chat/MessageForm';
import NewsFeed from './NewsFeed';
import { newadmindb } from '../common/Constants';
import imagePdf from '../static/images/pdf.png';
import imageVideo from '../static/images/video.jpg';
import imageUndefined from '../static/images/imageUndefined.png';
import { countUndoneTasks, convertDateToWithDots, countNbr } from '../common/functions';
import { getTaskComment,
  editCommentTaskAction,
  setRefresh,
  postCommentTaskActions,
  downloadFiles,
  chatMarkRead,
} from '../actions';
import { logOut } from '../common/Frame';


const useStyles = makeStyles()((theme) => { return {  
  root: {
    display: 'inline-block',
  },
  tooltip: {
    color: "#000000",
  },
  btnOuvrir: {
    textTransform: "none",
    fontSize: ".95rem",
    width: '90%',
    whiteSpace: 'pre',
  },
  buttonLowerCase: {
    textTransform: "none",
  },
  headers1: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    color: "grey",
  },
  headers2: {
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  bodyHeight: {
    minHeight: '60vh',
  },
  headFiles: {
    fontSize: '0.9em',
    marginLeft: theme.spacing(1),
    display: 'inline-block',
    verticalAlign: 'top',
    textAlign: '-webkit-center',
  },
  diasebledAddMail: {
    margin: theme.spacing(1.5),
    color: 'gainsboro',
  },
  dividerPinned: {
    marginTop: theme.spacing(2),
    color: 'gray',
  },
  tabs: {
    display: 'flex',
    justifyContent: 'center',
  },
  dialogTitle: {
    paddingTop: 0,

    '& .MuiCardMedia-media': {
      width: '40px',
      textAlign: 'center',
      lineHeight: '50px',
    },
  },
  displayNone: {
    display: 'none',
  },
  showMore: {
    whiteSpace: 'pre-wrap',
    verticalAlign: 'top',
    width: '36px',
    marginLeft: theme.spacing(.5),
  },
  tabNotZero: {
    margin: '0 0 4px 0 !important',
    padding: '0 8px !important',
  },
  iconTask: {
    '& .MuiSvgIcon-root, img': {
      verticalAlign: 'middle',
    },
  },
  imgFade: {
    opacity: '0.4',
  },
  subHeader: {
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerButtons: {
    display: 'flex',
    gap: '1em',
  },
  downloadFiles: {
    marginTop: 5,
    marginBottom: 8,
    margin: '8px 0',
    fontSize: '.8em',
  },
  chatMarkRead: {
    marginTop: 5,
    marginBottom: 8,
    margin: '8px 0',
    fontSize: '.8em',
    borderColor: '#4caf50',

    '&:hover': {
      color: '#fff',
      backgroundColor: '#4caf50',
      borderColor: '#4caf50',
    },
  },
  chatPageTab3: {
    textTransform: "none",
    fontSize: ".95rem",
    width: '90%',
    borderColor: '#4caf50',
    whiteSpace: 'pre',
    marginBottom: '1rem',

    '&:hover': {
      color: '#fff',
      backgroundColor: '#4caf50',
    },
  },
}});
;

const DialogTitle = withStyles((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle component="div" {...other}>
        <Typography variant="subtitle2">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            size="large">
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  }, (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
}));

const DialogContent = withStyles(MuiDialogContent, (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

const tabNames = {
  0: 'Tous',
  1: 'Transporteur',
  2: 'Assurance',
  3: 'Client',
};

function CorrespondencePopUp(props) {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const [showMoreFiles, setShowMoreFiles] = useState(false);
  const [tabsValue, setTabsValue] = useState(0);

  // props from parent:
  // parentId (number),
  // goToTab (number),
  // headerTop(component),
  // isRequest (boolean),
  // isShipment (boolean),
  // customer_id (number),
  // tracking_nbr (string),
  // countCommentsAndTasks (object),
  // disablePopUp (boolean),
  const { parentId,
    goToTab,
    headerTop, // component
    isShipment,
    customer_id,
    tracking_nbr,
    users,
    taskComment,
    getTaskComment,
    editCommentTaskAction,
    refresh,
    setRefresh,
    postCommentTaskActions,
    isRequest,
    countCommentsAndTasks,
    disablePopUp,
    downloadFiles,
    downloadFilesLoad,
    isChatPage,
    email,
    chatMarkReadLoad,
    chatMarkRead,
  } = props;
  
  const history = useHistory();
  
  const username = localStorage.getItem('username');
  if(!username) logOut();
  const currentUser = users.filter(filt => (filt.username.toLowerCase() === username.toLowerCase()))[0] === undefined ? 0 : users.filter(filt => (filt.username.toLowerCase() === username.toLowerCase()))[0].id;

  const postCommentTask = (comment, file, toUsers, dueDate, isTask, isChat, cEmail) => {
    const args = { comment,
      file,
      toUsers,
      dueDate,
      isTask,
      customer_id,
      parentId,
      currentUser,
      tracking_nbr,
      tabsValue,
      isShipment,
      isChat,
      email: cEmail ? cEmail : email,
    };

    postCommentTaskActions(args)
      .finally(() => {
        setRefresh(refresh + 1);
        getTaskComment(parentId, isRequest);
      });
  };

  function editCommentTask(id, taskStatus, newCommentTask, personName, dueTo, pinned) {
    const body = { due_date: dueTo,
      comment: newCommentTask,
      to_users: personName,
      task_status: taskStatus,
      updated_by: currentUser,
      pinned: pinned,
    };

    editCommentTaskAction(id, body)
      .finally(() => {
        setRefresh(refresh + 1);
        getTaskComment(parentId, isRequest);
      });
  }

  function deleteComment(id) {
    editCommentTaskAction(id, { is_actif: 0 })
      .finally(() => {
        setRefresh(refresh + 1);
        getTaskComment(parentId, isRequest);
      });
  }

  const handleClickOpen = () => {
    getTaskComment(parentId, isRequest);
    setOpen(true);
    setTabsValue(goToTab);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const tabsChange = (_, newValue) => {
    if(newValue !== tabsValue) {
      setTabsValue(newValue);
    }
  };

  let filenames = [];

  const today = (new Date().getFullYear()).toString()+'-'+(new Date().getMonth()+1).toString().padStart(2, '0')+'-'+(new Date().getDate()).toString().padStart(2, '0');
  
  const yesterday = (new Date().getFullYear()).toString()+'-'+(new Date().getMonth()+1).toString().padStart(2, '0')+'-'+(new Date().getDate()-1).toString().padStart(2, '0');

  const forNewsFeed = taskComment.filter(comment => (tabsValue === 0 ? true : (comment.with === tabsValue))).filter(el => (el.pinned === 0)).reduce((myResult, item) => {
    let current_date = (new Date(item.created_at*1000).getFullYear()).toString()+'-'+(new Date(item.created_at*1000).getMonth()+1).toString().padStart(2, '0')+'-'+(new Date(item.created_at*1000).getDate()).toString().padStart(2, '0');
    if (!myResult[current_date]) myResult[current_date] = [];
    myResult[current_date].push(item);
    return myResult;
  }, {});

  const newsFeedListPinned = taskComment.filter(comment => (tabsValue === 0 ? true : (comment.with === tabsValue))).filter(el => (el.pinned !== 0)).sort(function(a, b){return a.pinned - b.pinned}).map(pinned => {
    return <div key={pinned.id}>
        <NewsFeed
          id={pinned.id}
          comment={pinned.comment}
          files={pinned.files}
          showFiles={pinned.files ? (pinned.files.slice(0, -1).split(';').map(file => filenames.push({ id: pinned.id, file: file}))) : null}
          toUsers={pinned.to_users !== null ? pinned.to_users : ''}
          isTask={pinned.is_task}
          taskStatus={pinned.task_status}
          createdBy={pinned.created_by}
          updatedBy={pinned.updated_by}
          createdDate={pinned.created_at}
          updatedDate={pinned.updated_at}
          dueDate={pinned.due_date}
          editCommentTask={editCommentTask}
          deleteComment={deleteComment}
          users={users}
          pinned={true}
          pinnedExist={true}
          tabsValue={tabsValue}
          taskCommentTabValue={pinned.with}
          isChat={pinned.is_chat}
          isNew={pinned.is_new}
          key={pinned.id}
        />
      </div>;
  });

  const newsFeedList = Object.keys(forNewsFeed).map(groupByDate => {
    return <div key={groupByDate}>
      <h3 style={{textAlign: 'center'}}>{groupByDate === today ? 'Aujourd’hui' : groupByDate === yesterday ? 'Hier' : convertDateToWithDots(groupByDate)}</h3>
      {forNewsFeed[groupByDate].map(item => <NewsFeed
          id={item.id}
          comment={item.comment}
          files={item.files}
          showFiles={item.files ? (item.files.slice(0, -1).split(';').map(file => filenames.push({ id: item.id, file: file}))) : null}
          toUsers={item.to_users !== null ? item.to_users : ''}
          isTask={item.is_task}
          taskStatus={item.task_status}
          createdBy={item.created_by}
          updatedBy={item.updated_by}
          createdDate={item.created_at}
          updatedDate={item.updated_at}
          dueDate={item.due_date}
          editCommentTask={editCommentTask}
          deleteComment={deleteComment}
          parentId={parentId}
          users={users}
          pinned={false}
          pinnedExist={taskComment.filter(comment => (tabsValue === 0 ? 1 : (comment.with === tabsValue))).filter(el => (el.pinned !== 0)).length === 1}
          tabsValue={tabsValue}
          taskCommentTabValue={item.with}
          isChat={item.is_chat}
          isNew={item.is_new}
          key={item.id}
        />)}
      </div>;
  });

  function buttonTitle() {
    if(isChatPage && (goToTab === 3)) {
      return `New Chats (${countNbr(countCommentsAndTasks, parentId, 'nbr_chat_new')})`;
    } else if(isChatPage) {
      return `Total Chats (${countNbr(countCommentsAndTasks, parentId, 'nbr_chat')})\nTâches à faire (${countUndoneTasks(countCommentsAndTasks, parentId)})\nCorrespondances (${countNbr(countCommentsAndTasks, parentId, 'nbr_comments')})`;
    } else if(goToTab === 0) {
      return `Tâches à faire (${countUndoneTasks(countCommentsAndTasks, parentId)})\nCorrespondances (${countNbr(countCommentsAndTasks, parentId, 'nbr_comments')})`;
    } else if (goToTab === 1) {
      return <span className={classes.iconTask}><LocalShippingOutlinedIcon /> ({countUndoneTasks(countCommentsAndTasks, parentId, goToTab)})</span>;

    } else if (goToTab === 2) {     
      return (
        <span className={classes.iconTask}>
          <img className={disablePopUp ? classes.imgFade : ''} src={imgAxa} alt="Axa" width="20" /> ({countUndoneTasks(countCommentsAndTasks, parentId, goToTab)})
        </span>
      );

    } else if (goToTab === 3) {
      return <span className={classes.iconTask}><PersonOutlineOutlinedIcon /> ({countUndoneTasks(countCommentsAndTasks, parentId, goToTab)})</span>;      
    }
  }

  const handleDownloadAll = () => {
    downloadFiles(parentId, tracking_nbr);
  }

  const handleMarkMsgAsRead = () => {
    chatMarkRead(parentId);
  }

  const countFile = (history.location.pathname === '/tracking') ? 11 : 12;
  const fileArrayBeforeCut = filenames.length > countFile ? filenames.slice(0, countFile) : filenames;
  const fileArrayAfterCut = filenames.length > countFile ? filenames.slice(countFile) : [];
  
  function filesArea(array) {
    return (
      array.map((u, i) => (<span key={i} className={classes.inlineFlex}>
        <a
          className={classes.headFiles}
          rel="noopener noreferrer"
          href={`${newadmindb}/basic/web/uploads/${u.id}/${u.file}`}
          target="_blank"
        >
          <CardMedia
            component="img"
            alt={u.file.split('.').pop()}
            height="50"
            width="40"
            image={(u.file.split('.').pop().toLowerCase() === 'jpg' || u.file.split('.').pop().toLowerCase() === 'jpeg' || u.file.split('.').pop().toLowerCase() === 'gif') ? `${newadmindb}/basic/web/uploads/${u.id}/${u.file}`
              : u.file.split('.').pop() === 'pdf' ? imagePdf
              : (u.file.split('.').pop().toLowerCase() === 'mov' || u.file.split('.').pop().toLowerCase() === 'mp4') ? imageVideo
              : imageUndefined        
            }
            title={u.file}
          />
          {u.file.length > 9 ? `${u.file.slice(0, 8)}...` : u.file}
        </a>
      </span>))
    );
  };
  
  function showMore() {
    return (
      <Button
        color="secondary"
        variant="outlined"
        className={fileArrayAfterCut.length > 0 ? classes.showMore : classes.displayNone}
        onClick={() => {
          setShowMoreFiles(!showMoreFiles);
        }}
      >
        {showMoreFiles ? 'Show\nLess' : 'Show\nMore'}
      </Button>
    );
  };

  const downloadAllButton = (
    <Button
      disabled={!filenames.length || downloadFilesLoad}
      className={classes.downloadFiles}
      variant="outlined"
      size="small"
      onClick={handleDownloadAll}>
      <GetAppIcon fontSize="small" />
      &nbsp;{downloadFilesLoad ? 'Please wait...' : 'Download All'}
    </Button>
  );

  const markMsgAsReadButton = (
    <Button
      disabled={chatMarkReadLoad}
      className={classes.chatMarkRead}
      variant="outlined"
      size="small"
      onClick={handleMarkMsgAsRead}
    >
      {chatMarkReadLoad ? 'Please wait...' : 'Mark Messages As Read'}
    </Button>
  );

  const gridSize = 4;

  return (
    <div className={classes.root}>
      <Button
        disabled={disablePopUp}
        variant="outlined"
        size="large"
        className={(goToTab === 0) ? classes.btnOuvrir 
          : (isChatPage && (goToTab === 3)) ? classes.chatPageTab3
        : classes.tabNotZero}
        onClick={handleClickOpen}
      >
        {buttonTitle()}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="md"
      >
        {headerTop}
        <DialogTitle id="customized-dialog-title" className={classes.dialogTitle}>
            <Grid
              container 
              spacing={1}
              direction="row"
              justifyContent="space-around"
              alignItems="flex-start"
              align="center"
              className={classes.tooltip}
            >
              <Grid item xs={12} className={classes.tabs}>
                <Tabs
                  value={tabsValue}
                  indicatorColor="secondary"
                  textColor="secondary"
                  onChange={tabsChange}
                >
                  <Tab label={tabNames[0]} />
                  <Tab label={tabNames[1]} />
                  <Tab label={tabNames[2]} />
                  <Tab label={tabNames[3]} />
                </Tabs>
              </Grid>
              {tabsValue === 0 ? null : <React.Fragment>
                <Grid item xs={gridSize}>Ecrire un email</Grid>
                <Grid item xs={gridSize}>Ajouter une tâche</Grid>
                <Grid item xs={gridSize}>Ajouter un commentaire</Grid>
                
                <Grid item xs={gridSize}>
                  <AddCircleOutlineRoundedIcon className={classes.diasebledAddMail} disabled />
                </Grid>
                <Grid item xs={gridSize}>
                  <TaskForm postCommentTask={postCommentTask} />
                </Grid>
                <Grid item xs={gridSize}>
                  <CommentForm postCommentTask={postCommentTask} />
                </Grid>
              </React.Fragment>}
            </Grid>
          <Divider />
          <div>
            <div className={classes.subHeader}>
              <Typography className={classes.headers2}>
                Tâches à faire ({countUndoneTasks(countCommentsAndTasks, parentId, tabsValue)})
              </Typography>
              <div className={classes.headerButtons}>
                {(Number(countNbr(countCommentsAndTasks, parentId, 'nbr_chat_new')) && (tabsValue === 0 || tabsValue === 3)) ? markMsgAsReadButton : null}
                {tabsValue === 0 ? downloadAllButton : null}
              </div>
            </div>
            {filesArea(fileArrayBeforeCut)}
            {showMore()}
            {showMoreFiles && filesArea(fileArrayAfterCut)}
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.bodyHeight}>
            {newsFeedListPinned}
            {taskComment.filter(el => (el.pinned && el.with === tabsValue)).length > 0 ? <Divider className={classes.dividerPinned} /> : null}
            {(taskComment.filter(el => (el.pinned)).length > 0 && tabsValue === 0) ? <Divider className={classes.dividerPinned} /> : null}
            {newsFeedList}
          </div>
        </DialogContent>
        {(tabsValue === 3 & isShipment) ? <MessageFormChat postCommentTask={postCommentTask} /> : null}
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    taskComment: state.taskComment,
    users: state.users,
    refresh: state.refresh,
    downloadFilesLoad: state.downloadFilesLoad,
    chatMarkReadLoad: state.chatMarkReadLoad,
  };
};

export default connect(mapStateToProps, {
  getTaskComment,
  editCommentTaskAction,
  setRefresh,
  postCommentTaskActions,
  downloadFiles,
  chatMarkRead,
})(CorrespondencePopUp);
