import { TableCell } from "@mui/material";

export function ValueCell({children}) {
	return <TableCell>{children}</TableCell>;
}

export function DeltaCell({ value }) {
	if (value > 0) {
		return <TableCell className="delta positive">{value}</TableCell>;
	}
	else if (value < 0) {
		return <TableCell className="delta negative">{value}</TableCell>;
	}
	else {
		return <TableCell></TableCell>;
	}
}

export function roundWell(number) {
	const result = Math.round(number * 100) / 100;
	// Mettre des apostrophes pour les milliers
	return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}