import Select from "react-select";
import makeAnimated from "react-select/animated";
import { makeStyles } from "tss-react/mui";
import PropTypes from 'prop-types';

export const yearsOptions = Array.from({ length: new Date().getFullYear() - 2014 }, (_, i) => ({
	value: new Date().getFullYear() - i,
	label: new Date().getFullYear() - i,
}));

const useStyles = makeStyles()((theme) => { return {
	selector: {
		width : '100%',
	},
}});

const animatedComponents = makeAnimated();

function YearsComparison ({ selected, setSelected }) {
	const { classes } = useStyles();
	return (
		<Select
			options={yearsOptions}
			onChange={setSelected}
			isMulti
			value={selected}
			className={classes.selector}
			components={animatedComponents}
			closeMenuOnSelect={false}
			placeholder="Comparer des années"
		/>
	);
}

YearsComparison.propTypes = {
	selected: PropTypes.arrayOf(PropTypes.object),
	setSelected: PropTypes.func,
}

export default YearsComparison;