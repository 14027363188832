import axios from "axios";
import { URL_All } from "../../../common/Constants";

export async function fetchInsurancesData(signal, token, shipment_type, months_period, years_comparison, transporterId, display) {
	const response = await axios.post(URL_All.stats + '/insurances/', JSON.stringify({
		shipment_type: shipment_type,
		months_period: months_period,
		years_comparison: years_comparison,
		transporter_id: transporterId,
		display: display
	}), {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'JWT ' + token
		},
		signal: signal
	});

	let keys = ['total_insurances', 'documents', 'parcels', 'pallets', 'ca_insurance', 'mb_insurance'];

	// On trie les mois par mois et par année (ex: Janvier 2021, Janvier 2022, Janvier 2023 puis Février 2021, Février 2022, Février 2023, etc.)
	response.data.monthsData.sort((a, b) => {
        if (a.month !== b.month) {
            return a.month - b.month; // Trier par mois
        } else {
            return b.year - a.year; // Si les mois sont égaux, trier par année
        }
    });


	// On change a.month qui sont des number en string pour former "Janvier" par exemple
	for (let i = 0; i < response.data.monthsData.length; i++) {
		const month = response.data.monthsData[i];
		month.month = new Date(2021, month.month - 1, 1).toLocaleString('default', { month: 'long' });
	}


	// Pour toutes les entrées de monthsData, on ajoute des champs delta au response.data.monthsData.content (qui est un objet) pour chaque entrée (ex: response.data.monthsData.content.ca_total à ca_total_delta, mb_total, mb_total_delta, etc.)
	for (let i = 0; i < response.data.monthsData.length; i++) {
		const month = response.data.monthsData[i];
		const monthContent = month.content;
		for (let j = 0; j < keys.length; j++) {
			const key = keys[j];
			const deltaKey = key + '_delta';
			// reprendre la valeur de la meme clé dans le mois suivant sauf si le mois n'est pas le meme
			const previousMonth = response.data.monthsData[i + 1];
			if (previousMonth && previousMonth.month === month.month) {
				// monthContent[deltaKey] = Math.round((monthContent[key] - previousMonth.content[key]) / previousMonth.content[key] * 100 * 100) / 100;
				monthContent[deltaKey] = (monthContent[key] - previousMonth.content[key]) / previousMonth.content[key] * 100;
				if (isNaN(monthContent[deltaKey])) {
					monthContent[deltaKey] = 0;
				}
			} else {
				monthContent[deltaKey] = 0;
			}
		}
	}
	return response;
}