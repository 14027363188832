import React from 'react';
import { makeStyles } from 'tss-react/mui';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Table, TableHead } from '@mui/material';
import { DeltaCell, roundWell, ValueCell } from '../components/CellUtils';

const borderColor = '#010101';

const useStyles = makeStyles()((theme) => {
	return {
		tableContainer: {
			marginTop: theme.spacing(2),
			overflowX: 'auto',
			width: '100%',
			minHeight: '400px'
		},
		tableHead:{
			background: '#d6dce4',
			whiteSpace: 'nowrap',
			
			'& th': {
				textAlign: 'center',
				fontWeight: '700',
				borderTop: `1px solid ${borderColor}`,
				borderRight: `1px solid ${borderColor}`,
				borderBottom: `1px solid ${borderColor}`,
			},
		
			'& th:first-of-type': {
				borderLeft: `1px solid ${borderColor}`,
			},
		},
		differentRows: {
			overflowWrap: 'anywhere',

			'& tr:nth-of-type(even)': {
				backgroundColor: '#d9d9d9',
			},

			'& tr.italic': {
				fontStyle: 'italic',
			},

			'& tr.total': {
				backgroundColor: '#b4c6e7',

				'& td': {
					fontWeight: '700',
				},
			},

			'& td': {
				borderRight: `1px solid ${borderColor}`,
				borderBottom: `1px solid ${borderColor}`,
				whiteSpace: 'nowrap',
				padding: 0,
				paddingLeft: '2px',
				paddingRight: '2px'
			},

			'& td:not(:first-of-type)': {
				textAlign: 'end',
			},

			'& td:first-of-type': {
				borderLeft: `1px solid ${borderColor}`,
			},

			'& td:not(.delta)': {
			},

			'& td.delta': {
				textAlign: 'center',

				'&.positive': {
					backgroundColor: '#c6e0b4',
				},

				'&.negative': {
					backgroundColor: '#fa9a9a',
				},
			},
		},
		loading: {
			textAlign: 'center',
			height: '50vh',
			color: '#777',
			fontSize: 72,
			fontStyle: 'italic',
		},
	}
});

export default function StatisticsCustomersTable({ data, isActivity, isPercent }) {
	const { classes } = useStyles();

	return (
		<div className={classes.tableContainer}>
		<Table>
			<TableHead>
				<TableRow className={classes.tableHead}>
					<TableCell>Mois</TableCell>
					<TableCell>Nouv. partic. actifs {isPercent ? "(%)" : null}</TableCell>
					<TableCell>Δ%</TableCell>
					<TableCell>Partic. actifs {isPercent ? "(%)" : null}</TableCell>
					<TableCell>Δ%</TableCell>
					<TableCell>Vol. colis. partic. {isPercent ? "(%)" : null}</TableCell>
					<TableCell>Δ%</TableCell>
					<TableCell>Vol. docs. partic. {isPercent ? "(%)" : null}</TableCell>
					<TableCell>Δ%</TableCell>
					<TableCell>Vol. pal. partic. {isPercent ? "(%)" : null}</TableCell>
					<TableCell>Δ%</TableCell>
					<TableCell>Total partic. {isPercent ? "(%)" : null}</TableCell>
					<TableCell>Δ%</TableCell>
					<TableCell>Nouv. entr. actives {isPercent ? "(%)" : null}</TableCell>
					<TableCell>Δ%</TableCell>
					<TableCell>Entr. actives {isPercent ? "(%)" : null}</TableCell>
					<TableCell>Δ%</TableCell>
					<TableCell>Vol. colis. entr. {isPercent ? "(%)" : null}</TableCell>
					<TableCell>Δ%</TableCell>
					<TableCell>Vol. docs. entr. {isPercent ? "(%)" : null}</TableCell>
					<TableCell>Δ%</TableCell>
					<TableCell>Vol. pal. entr. {isPercent ? "(%)" : null}</TableCell>
					<TableCell>Δ%</TableCell>
					<TableCell>Total entr. {isPercent ? "(%)" : null}</TableCell>
					<TableCell>Δ%</TableCell>
					<TableCell>Total clients ce mois {isPercent ? "(%)" : null}</TableCell>
					<TableCell>Δ%</TableCell>
					<TableCell>Total clients {isPercent ? "(%)" : null}</TableCell>
					<TableCell>Δ%</TableCell>
					</TableRow>
			</TableHead>
			<TableBody className={classes.differentRows}>
				{data.map((row, index) => (
					<TableRow key={index}>
						<ValueCell>{row.month} {row.year}</ValueCell>
						<ValueCell>{roundWell(row.content.new_individuals)}</ValueCell>
						<DeltaCell value={roundWell(row.content.new_individuals_delta)} />
						<ValueCell>{roundWell(row.content.individuals)}</ValueCell>
						<DeltaCell value={roundWell(row.content.individuals_delta)} />
						<ValueCell>{roundWell(row.content.individuals_parcels)}</ValueCell>
						<DeltaCell value={roundWell(row.content.individuals_parcels_delta)} />
						<ValueCell>{roundWell(row.content.individuals_documents)}</ValueCell>
						<DeltaCell value={roundWell(row.content.individuals_documents_delta)} />
						<ValueCell>{roundWell(row.content.individuals_pallets)}</ValueCell>
						<DeltaCell value={roundWell(row.content.individuals_pallets_delta)} />
						<ValueCell>{roundWell(row.content.total_individuals_until)}</ValueCell>
						<DeltaCell value={roundWell(row.content.total_individuals_until_delta)} />
						<ValueCell>{roundWell(row.content.new_companies)}</ValueCell>
						<DeltaCell value={roundWell(row.content.new_companies_delta)} />
						<ValueCell>{roundWell(row.content.companies)}</ValueCell>
						<DeltaCell value={roundWell(row.content.companies_delta)} />
						<ValueCell>{roundWell(row.content.companies_parcels)}</ValueCell>
						<DeltaCell value={roundWell(row.content.companies_parcels_delta)} />
						<ValueCell>{roundWell(row.content.companies_documents)}</ValueCell>
						<DeltaCell value={roundWell(row.content.companies_documents_delta)} />
						<ValueCell>{roundWell(row.content.companies_pallets)}</ValueCell>
						<DeltaCell value={roundWell(row.content.companies_pallets_delta)} />
						<ValueCell>{roundWell(row.content.total_companies_until)}</ValueCell>
						<DeltaCell value={roundWell(row.content.total_companies_until_delta)} />
						<ValueCell>{roundWell(row.content.total_customers_month)}</ValueCell>
						<DeltaCell value={roundWell(row.content.total_customers_month_delta)} />
						<ValueCell>{roundWell(row.content.total_customers)}</ValueCell>
						<DeltaCell value={roundWell(row.content.total_customers_delta)} />
						<DeltaCell/>
					</TableRow>
				))}
			</TableBody>
		</Table>
		</div>
	)
}