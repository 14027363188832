import axios from "axios";
import { URL_All } from "../../../common/Constants";

export async function fetchDestinationsData(signal, token, shipment_type, months_period, years_comparison, transporterId, display) {
	const response = await axios.post(URL_All.stats + '/destinations/', JSON.stringify({
		shipment_type: shipment_type,
		months_period: months_period,
		years_comparison: years_comparison,
		transporter_id: transporterId,
		display: display
	}), {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'JWT ' + token
		},
		signal: signal
	});

	let keys = ['total_ca', 'total_mb', 'ca_companies', 'mb_companies', 'ca_individuals', 'mb_individuals'];

	// On trie les mois par mois et par année (ex: Janvier 2021, Janvier 2022, Janvier 2023 puis Février 2021, Février 2022, Février 2023, etc.)
	response.data.monthsData.sort((a, b) => {
        if (a.month !== b.month) {
            return a.month - b.month; // Trier par mois
        } else {
            return b.year - a.year; // Si les mois sont égaux, trier par année
        }
    });

	// On change a.month qui sont des number en string pour former "Janvier" par exemple
	for (let i = 0; i < response.data.monthsData.length; i++) {
		const month = response.data.monthsData[i];
		month.month = new Date(2021, month.month - 1, 1).toLocaleString('default', { month: 'long' });
	}

	let countries = {};
	for (let i = 0; i < response.data.monthsData.length; i++) {
		const month = response.data.monthsData[i];
		const monthContent = month.content;
		if (!countries[monthContent.country_code]) {
			countries[monthContent.country_code] = {
				country_code: monthContent.country_code,
				total_ca: 0,
				total_mb: 0,
				ca_companies: 0,
				mb_companies: 0,
				ca_individuals: 0,
				mb_individuals: 0
			};
		}
		countries[monthContent.country_code].total_ca += monthContent.total_ca;
		countries[monthContent.country_code].total_mb += monthContent.total_mb;
		countries[monthContent.country_code].ca_companies += monthContent.ca_companies;
		countries[monthContent.country_code].mb_companies += monthContent.mb_companies;
		countries[monthContent.country_code].ca_individuals += monthContent.ca_individuals;
		countries[monthContent.country_code].mb_individuals += monthContent.mb_individuals;
	}
	response.data.monthsData = [];
	for (let key in countries) {
		const client = countries[key];
		response.data.monthsData.push({
			year: response.data.year,
			month: '-',
			content: client
		});
	}

	// On trie les countries par total_ca
	response.data.monthsData.sort((a, b) => b.content.total_ca - a.content.total_ca);

	return response;
}