import React from 'react';
import { makeStyles } from 'tss-react/mui';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Table, TableHead } from '@mui/material';
import { DeltaCell, roundWell, ValueCell } from '../components/CellUtils';

const borderColor = '#010101';

const useStyles = makeStyles()((theme) => {
	return {
		tableContainer: {
			marginTop: theme.spacing(2),
			overflowX: 'auto',
			width: '100%',
			minHeight: '400px'
		},
		tableHead:{
			background: '#d6dce4',
			whiteSpace: 'nowrap',
			
			'& th': {
				textAlign: 'center',
				fontWeight: '700',
				borderTop: `1px solid ${borderColor}`,
				borderRight: `1px solid ${borderColor}`,
				borderBottom: `1px solid ${borderColor}`,
			},
		
			'& th:first-of-type': {
				borderLeft: `1px solid ${borderColor}`,
			},
		},
		differentRows: {
			overflowWrap: 'anywhere',

			'& tr:nth-of-type(even)': {
				backgroundColor: '#d9d9d9',
			},

			'& tr.italic': {
				fontStyle: 'italic',
			},

			'& tr.total': {
				backgroundColor: '#b4c6e7',

				'& td': {
					fontWeight: '700',
				},
			},

			'& td': {
				borderRight: `1px solid ${borderColor}`,
				borderBottom: `1px solid ${borderColor}`,
				whiteSpace: 'nowrap',
				padding: 0,
				paddingLeft: '2px',
				paddingRight: '2px'
			},

			'& td:not(:first-of-type)': {
				textAlign: 'end',
			},

			'& td:first-of-type': {
				borderLeft: `1px solid ${borderColor}`,
			},

			'& td:not(.delta)': {
			},

			'& td.delta': {
				textAlign: 'center',

				'&.positive': {
					backgroundColor: '#c6e0b4',
				},

				'&.negative': {
					backgroundColor: '#fa9a9a',
				},
			},
		},
		loading: {
			textAlign: 'center',
			height: '50vh',
			color: '#777',
			fontSize: 72,
			fontStyle: 'italic',
		},
	}
});

export default function StatisticsBillingTable({ data, pareto, isPercent }) {
	const { classes } = useStyles();

	return (
		<div className={classes.tableContainer}>
		<Table>
			<TableHead>
				<TableRow className={classes.tableHead}>
					{ pareto ? <TableCell>Top</TableCell> : <TableCell>Mois</TableCell> }
					{ pareto ? <TableCell>Nom du client</TableCell> : null }
					<TableCell>Surfacturation {isPercent ? "(%)" : "(CHF)"}</TableCell>
					{ pareto ? null : <TableCell>Δ%</TableCell> }
					<TableCell>Vol. surfacturation {isPercent ? "(%)" : "(CHF)"}</TableCell>
					{ pareto ? null : <TableCell>Δ%</TableCell> }
					<TableCell>Diff. prix d'achat indiqué (notre faveur) {isPercent ? "(%)" : "(CHF)"}</TableCell>
					{ pareto ? null : <TableCell>Δ%</TableCell> }
					<TableCell>Diff. prix d'achat indiqué (notre défaveur) {isPercent ? "(%)" : "(CHF)"}</TableCell>
					{ pareto ? null : <TableCell>Δ%</TableCell> }
					<TableCell>DDP {isPercent ? "(%)" : "(CHF)"}</TableCell>
					{ pareto ? null : <TableCell>Δ%</TableCell> }
					<TableCell>Carte de crédit {isPercent ? "(%)" : "(CHF)"}</TableCell>
					{ pareto ? null : <TableCell>Δ%</TableCell> }
					<TableCell>Facture individuelle {isPercent ? "(%)" : "(CHF)"}</TableCell>
					{ pareto ? null : <TableCell>Δ%</TableCell> }
					<TableCell>Facture mensuelle {isPercent ? "(%)" : "(CHF)"}</TableCell>
					{ pareto ? null : <TableCell>Δ%</TableCell> }
					</TableRow>
			</TableHead>
			<TableBody className={classes.differentRows}>
				{data.map((row, index) => (
					<TableRow key={index}>
						{
							pareto ? <ValueCell>{index + 1}</ValueCell> : <ValueCell>{row.month} {row.year}</ValueCell>
						}
						{
							pareto ? <ValueCell>{row.content.client_name}</ValueCell> : null
						}
						<ValueCell>{roundWell(row.content.overbilling)}</ValueCell>
						{ pareto ? null : <DeltaCell value={roundWell(row.content.overbilling_delta)} /> }
						<ValueCell>{roundWell(row.content.overbilling_volume)}</ValueCell>
						{ pareto ? null : <DeltaCell value={roundWell(row.content.overbilling_volume_delta)} /> }
						<ValueCell>{roundWell(row.content.overbilling_favor)}</ValueCell>
						{ pareto ? null : <DeltaCell value={roundWell(row.content.overbilling_favor_delta)} /> }
						<ValueCell>{roundWell(row.content.overbilling_unfavor)}</ValueCell>
						{ pareto ? null : <DeltaCell value={roundWell(row.content.overbilling_unfavor_delta)} /> }
						<ValueCell>{roundWell(row.content.ddp)}</ValueCell>
						{ pareto ? null : <DeltaCell value={roundWell(row.content.ddp_delta)} /> }
						<ValueCell>{roundWell(row.content.credit_card)}</ValueCell>
						{ pareto ? null : <DeltaCell value={roundWell(row.content.credit_card_delta)} /> }
						<ValueCell>{roundWell(row.content.individual_invoice)}</ValueCell>
						{ pareto ? null : <DeltaCell value={roundWell(row.content.individual_invoice_delta)} /> }
						<ValueCell>{roundWell(row.content.monthly_invoice)}</ValueCell>
						{ pareto ? null : <DeltaCell value={roundWell(row.content.monthly_invoice_delta)} /> }
					</TableRow>
				))}
			</TableBody>
		</Table>
		</div>
	)
}