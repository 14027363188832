import axios from "axios";
import { URL_All } from "../../../common/Constants";

export async function fetchFinanceData(signal, token, shipment_type, months_period, years_comparison, pareto, display) {
	const response = await axios.post(URL_All.stats + '/finance/', JSON.stringify({
		shipment_type: shipment_type,
		months_period: months_period,
		years_comparison: years_comparison,
		pareto: pareto,
		display: display
	}), {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'JWT ' + token
		},
		signal: signal
	});

	// On trie les mois par mois et par année (ex: Janvier 2021, Janvier 2022, Janvier 2023 puis Février 2021, Février 2022, Février 2023, etc.)
	response.data.monthsData.sort((a, b) => {
        if (a.month !== b.month) {
            return a.month - b.month; // Trier par mois
        } else {
            return b.year - a.year; // Si les mois sont égaux, trier par année
        }
    });


	// On change a.month qui sont des number en string pour former "Janvier" par exemple
	for (let i = 0; i < response.data.monthsData.length; i++) {
		const month = response.data.monthsData[i];
		month.month = new Date(2021, month.month - 1, 1).toLocaleString('default', { month: 'long' });
	}

	let keys = ['ca_total', 'mb_total', 'ca_shipment', 'mb_shipment', 'ca_insurance', 'mb_insurance', 'ca_personal_offer', 'mb_personal_offer', 'discount', 'overbilling', 'ddp', 'credit_card', 'individual_invoice', 'monthly_invoice'];

	if (pareto <= 0) {
		// Pour toutes les entrées de monthsData, on ajoute des champs delta au response.data.monthsData.content (qui est un objet) pour chaque entrée (ex: response.data.monthsData.content.ca_total à ca_total_delta, mb_total, mb_total_delta, etc.)
		for (let i = 0; i < response.data.monthsData.length; i++) {
			const month = response.data.monthsData[i];
			const monthContent = month.content;
			for (let j = 0; j < keys.length; j++) {
				const key = keys[j];
				const deltaKey = key + '_delta';
				// reprendre la valeur de la meme clé dans le mois suivant sauf si le mois n'est pas le meme
				const previousMonth = response.data.monthsData[i + 1];
				if (previousMonth && previousMonth.month === month.month) {
					// monthContent[deltaKey] = Math.round((monthContent[key] - previousMonth.content[key]) / previousMonth.content[key] * 100 * 100) / 100;
					monthContent[deltaKey] = (monthContent[key] - previousMonth.content[key]) / previousMonth.content[key] * 100;
					if (isNaN(monthContent[deltaKey])) {
						monthContent[deltaKey] = 0;
					}
				} else {
					monthContent[deltaKey] = 0;
				}
			}
		}
	}

	// On regroupe les clients qui ont le meme content.client_id, on les tri par ca_total grace à la somme qu'on aura fait avec content.ca_total, enfin au champ du mois (month) on met un '-' pour signifier qu'il y en a plusieurs
	if (pareto > 0) {
		let clients = {};
		for (let i = 0; i < response.data.monthsData.length; i++) {
			const month = response.data.monthsData[i];
			const monthContent = month.content;
			if (!clients[monthContent.client_id]) {
				clients[monthContent.client_id] = {
					client_id: monthContent.client_id,
					client_name: monthContent.client_name,
					ca_total: 0,
					mb_total: 0,
					ca_shipment: 0,
					mb_shipment: 0,
					ca_insurance: 0,
					mb_insurance: 0,
					ca_personal_offer: 0,
					mb_personal_offer: 0,
					discount: 0,
					overbilling: 0,
					ddp: 0,
					credit_card: 0,
					individual_invoice: 0,
					monthly_invoice: 0
				};
			}
			clients[monthContent.client_id].ca_total += monthContent.ca_total;
			clients[monthContent.client_id].mb_total += monthContent.mb_total;
			clients[monthContent.client_id].ca_shipment += monthContent.ca_shipment;
			clients[monthContent.client_id].mb_shipment += monthContent.mb_shipment;
			clients[monthContent.client_id].ca_insurance += monthContent.ca_insurance;
			clients[monthContent.client_id].mb_insurance += monthContent.mb_insurance;
			clients[monthContent.client_id].ca_personal_offer += monthContent.ca_personal_offer;
			clients[monthContent.client_id].mb_personal_offer += monthContent.mb_personal_offer;
			clients[monthContent.client_id].discount += monthContent.discount;
			clients[monthContent.client_id].overbilling += monthContent.overbilling;
			clients[monthContent.client_id].ddp += monthContent.ddp;
			clients[monthContent.client_id].credit_card += monthContent.credit_card;
			clients[monthContent.client_id].individual_invoice += monthContent.individual_invoice;
			clients[monthContent.client_id].monthly_invoice += monthContent.monthly_invoice;
		}
		response.data.monthsData = [];
		for (let key in clients) {
			const client = clients[key];
			response.data.monthsData.push({
				year: response.data.year,
				month: '-',
				content: client
			});
		}

		// On trie les clients par ca_total
		response.data.monthsData.sort((a, b) => b.content.ca_total - a.content.ca_total);
	}
	
	return response;
}