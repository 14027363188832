import Select from "react-select";
import { makeStyles } from "tss-react/mui";
import PropTypes from 'prop-types';

const options = [
	{ value: 0, label: 'Total' },
	{ value: 1, label: 'Export' },
	{ value: 2, label: 'Import' },
	{ value: 3, label: 'Domestique' },
	{ value: 4, label: 'Crossboarder' },
]

const useStyles = makeStyles()((theme) => { return {
	selector: {
		width : '100%',
	},
}});

function ShipmentType ({ selected, setSelected }) {
	const { classes } = useStyles();

	return (
		<Select
			options={options}
			onChange={(e) => setSelected(e.value)}
			value={options.find((option) => option.value === selected)}
			className={classes.selector}
		/>
	);
}

ShipmentType.propTypes = {
	selected: PropTypes.number,
	setSelected: PropTypes.func,
}

export default ShipmentType;