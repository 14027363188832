import React from 'react';
import { makeStyles } from 'tss-react/mui';
import ShipmentType from '../fields/ShipmentType';
import MonthsPeriod from '../fields/MonthsPeriod';
import YearsComparison from '../fields/YearsComparison';
import Display from '../fields/Display';
import PropTypes from 'prop-types'
import Pareto from '../fields/Pareto';

const useStyles = makeStyles()((theme) => { return {
	headerSelector: {
		width: '100%',
		display: 'flex',
		alignItems: 'flex-start',
		gap: '6px',
		paddingTop: theme.spacing(2),
	},
}});

function StatisticsFinanceControls({
	shipmentType,
	monthsPeriod,
	yearsComparison,
	pareto,
	display,
	setShipmentType,
	setMonthsPeriod,
	setYearsComparison,
	setPareto,
	setDisplay,
}) {
	const { classes } = useStyles();

	return (
		<div className={classes.headerSelector}>
			<ShipmentType selected={shipmentType} setSelected={setShipmentType} />
			<MonthsPeriod selected={monthsPeriod} setSelected={setMonthsPeriod} />
			<YearsComparison selected={yearsComparison} setSelected={setYearsComparison} />
			<Pareto selected={pareto} setSelected={setPareto} />
			<Display selected={display} setSelected={setDisplay} />
		</div>
	);
}

StatisticsFinanceControls.propTypes = {
	shipmentType: PropTypes.number,
	monthsPeriod: PropTypes.arrayOf(PropTypes.object),
	yearsComparison: PropTypes.arrayOf(PropTypes.object),
	pareto: PropTypes.number,
	display: PropTypes.number,
	setShipmentType: PropTypes.func,
	setMonthsPeriod: PropTypes.func,
	setYearsComparison: PropTypes.func,
	setPareto: PropTypes.func,
	setDisplay: PropTypes.func,
}

export default StatisticsFinanceControls;