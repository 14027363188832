import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FuseAnimate from '../fuse/FuseAnimate';
import useForm from '../fuse/useForm';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { makeStyles } from 'tss-react/mui';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { URL_All } from "../common/Constants";
import { setSnackbar } from '../actions';


const useStyles = makeStyles()((theme) => { return {
	root: {
        '& > *': {
            margin: theme.spacing(3),
		},
		height: '100vh',
        background: "radial-gradient(rgb(9, 17, 24) 0%, #122230 80%)",
		color: theme.palette.primary.contrastText,
		display: 'flex',
		flexDirection: 'column',
		flex: '1 1 auto',
		flexShrink: 0,
		alignItems: 'center',
		padding: '2.8rem',
		paddingTop: '4.6rem',
	},
	div2: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
	},
	card: {
		width: '100%',
		maxWidth: '22.4rem',
	},
	cardContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '2.8rem',
	},
	headType: {
		marginTop: '.6rem',
		marginBottom: '2.6rem',
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		width: '100%',
	},
	usernamePassword: {
		marginBottom: '1.6rem',
	},
	checkbox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	btn: {
		width: '14.4rem',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: '.6rem',
		marginBottom: '.6rem',

	},
	laoding: {
    display: 'inline-flex',
    gap: 5,
	},
}});

async function loginUser(credentials) {
	return fetch(URL_All.Login, {
	  method: 'POST',
	  headers: {
		'Content-Type': 'application/json'
	  },
	  body: JSON.stringify(credentials)
	})
	.then(data => data.json())
}

function LoginPage({ setToken, setSnackbar }) {
	const [username, setUsername] = useState();
	const [password, setPassword] = useState();
	const [incorrectLogin, setIncorrectLogin] = useState(false);
	const [loadingLogin, setLoadingLogin] = useState(false);
	const [refresh, setRefresh] = useState(0);
	const { classes } = useStyles();

	const { form, handleChange, resetForm } = useForm({
		username: '',
		password: '',
		remember: true
	});

	function verifyLoginUsername(args) {
		fetch(URL_All.VerifyLoginUsername + args)
			.catch(error => console.error(error));
	};

	const handleSubmit = async e => {
		const localStr = localStorage;
		if(localStr.length === 2) return window.location.reload();

		setLoadingLogin(true);

		e.preventDefault();
		const token = await loginUser({
		  username,
		  password
		});
		if(token.token) {
			localStorage.setItem('username', username);
			setToken(token);
			setSnackbar({
				open: true,
				severity: 2, // login welcome
			});
			verifyLoginUsername(username);
		} else {
			setIncorrectLogin(true);
		}

		setLoadingLogin(false);
		resetForm();
	}

  useEffect(() => {
    let interval;
    interval = setInterval(() => setRefresh(refresh + 1), 10000);
		
		const localStr = localStorage;
		if(localStr.length === 2) window.location.reload();
  
     return () => {
      clearInterval(interval);
    }
  }, [refresh, setRefresh]);

	return (
        <div className={classes.root}>
			<div className={classes.div2}>
				<FuseAnimate animation="transition.expandIn">
					<Card className={classes.card}>
						<CardContent className={classes.cardContent}>
							<Typography variant="h6" className={classes.headType}>
								LOGIN TO YOUR ACCOUNT
							</Typography>
							<form
								name="loginForm"
								noValidate
								className={classes.form}
								onSubmit={handleSubmit}
							>
								<TextField
									className={classes.usernamePassword}
									label="Username"
									autoFocus
									type="text"
									name="username"
									onChange={e => setUsername(e.target.value)}
									size="small"
									variant="outlined"
									required
									fullWidth
									error={incorrectLogin}
								/>
								<TextField
									className={classes.usernamePassword}
									label="Password"
									type="password"
									name="password"
									onChange={e => setPassword(e.target.value)}
									size="small"
									variant="outlined"
									required
									fullWidth
									error={incorrectLogin}
									helperText={incorrectLogin ? "Incorrect username or password." : ""}
								/>
								<div className={classes.checkbox}>
									<FormControl variant="standard">
										<FormControlLabel
											control={
												<Checkbox
													name="remember"
													checked={form.remember}
													onChange={handleChange}
												/>
											}
											label="Remember Me"
										/>
									</FormControl>
								</div>
								<Button
									disabled={loadingLogin}
									variant="contained"
									color="primary"
									className={classes.btn}
									aria-label="LOG IN"
									type="submit"
								>
									{loadingLogin ? <span className={classes.laoding}><CircularProgress size="1.7em" /> Loading...</span> : 'LOGIN'}
								</Button>
							</form>
						</CardContent>
					</Card>
				</FuseAnimate>
			</div>
		</div>
    );
}

LoginPage.propTypes = {
	setToken: PropTypes.func.isRequired
}

export default connect(null, { setSnackbar })(LoginPage);
