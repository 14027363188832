import { makeStyles } from "tss-react/mui";
import PropTypes from 'prop-types';
import { TextField } from "@mui/material";

const useStyles = makeStyles()((theme) => {
	return {
		selector: {
			width : '100%',
		},
	}
});

function Activity({ selected, setSelected }) {
	const { classes } = useStyles();

	return (
		<TextField
			type="number"
			onChange={(e) => {
				if (e.target.value === '') {
					setSelected(6)
					return
				}
				setSelected(parseInt(e.target.value))
			}}
			className={classes.selector}
			label="Activité (mois)"
		/>
	);
}

Activity.propTypes = {
	selected: PropTypes.number,
	setSelected: PropTypes.func,
	canPareto: PropTypes.bool
}

export default Activity;