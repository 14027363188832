import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Tooltip from '@mui/material/Tooltip';
import RefreshIcon from '@mui/icons-material/Refresh';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import PopUpPackage from './PopUpPackage';
import PurchasePriceEdit from './PurchasePriceEdit';
import taxationImg from '../static/images/taxation.png';
import overchargingImg from '../static/images/overcharging.png';
import { setInvoicesBilling,
  editInvoice,
  setInvoicesSort,
  getInvoices,
  setInvoiceCurrent,
} from '../actions';
import { convertDateToWithDots,
  numberTwoDecimal,
  convertDateToWithDash,
} from '../common/functions';
import useToken from '../login/useToken';
import { logOut } from '../common/Frame';


const useStyles = makeStyles()((theme) => { return {
  root: {
    background: '#F7F7F7',
  },
  tableHead:{
    background: '#727272',
    '& th': {
      textAlign: 'center',
      color: '#fff',
      fontWeight: '700',

      [theme.breakpoints.down(1600)]: {
        padding: theme.spacing(1),
      },
    },
  },
  tableRow: {
    '& td': {
      [theme.breakpoints.down(1600)]: {
        padding: theme.spacing(1),
      },
    },

    '& td:not(:first-of-type)': {
      textAlign: 'center',
    },
  },
  customerName: {
    overflowWrap: 'anywhere',
    width: 170,
    
    [theme.breakpoints.down(1800)]: {
    },
    [theme.breakpoints.down(1700)]: {
      width: 150,
    },
    [theme.breakpoints.down(1600)]: {
      width: 130,
    },
  },
  trackingNumber: {
    textTransform: 'none',
    textDecoration: 'underline',
    color: '#00f',
    maxWidth: 240,
    
    [theme.breakpoints.down(1800)]: {
      maxWidth: 170,
    },
    [theme.breakpoints.down(1700)]: {
      maxWidth: 150,
    },
    [theme.breakpoints.down(1600)]: {
      maxWidth: 130,
    },

    '& .button-text': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: "block",
    },
  },
  editPrice: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(.5),
  },
  dateOfControl: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .MuiButtonBase-root': {
      padding: theme.spacing(1),
    },
  },
  taxOvercharge: {
    display: 'flex',

    '&.disabled-buttons img': {
      filter: 'opacity(0.5)',
    },
  },
  loading: {
    height: '60vh',
    backgroundColor: '#f7f7f7',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),

    [theme.breakpoints.up(1600)]: {
      height: '70vh',
    },
  },
  emptyBody: {
    height: '60vh',
    backgroundColor: '#f7f7f7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.up(1600)]: {
      height: '70vh',
    },

    '& .MuiTypography-root': {
      textAlign: 'center',
      fontStyle: 'italic',
      fontSize: '2rem',
      color: '#9e9b9b',
    },
  },
  notFound: {
    height: '60vh',
    backgroundColor: '#f7f7f7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.up(1600)]: {
      height: '70vh',
    },

    '& .MuiTypography-root': {
      textAlign: 'center',
      fontStyle: 'italic',
      fontSize: '2rem',
      color: '#f00',
    },
  },
  sellingPriceHeader: {
    width: 130,
    lineHeight: '1',

    [theme.breakpoints.up(1700)]: {
      width: 160,
    },
  },
  oldAmount: {
    textDecoration: 'line-through',
    opacity: '.75',

  },
  controlledBy: {
    display: 'flex',
    justifyContent: 'center',

    '& p': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      margin: 0,
      maxWidth: 120,
  
      [theme.breakpoints.up(1600)]: {
        maxWidth: 140,
      },
    },
  },
}});

function Row(props) {
  const { token } = useToken();
  const { classes } = useStyles();
  const { setInvoicesBilling,
    editInvoice,
    invoiceEditResponse,
    setInvoiceCurrent,
  } = props;
  const [item, setItem] = useState(props.item);
  const [copyTitle, setCopyTitle] = useState(false);
  const username = localStorage.getItem('username');
  if(!username) logOut();

  const handleTaxation = () => {
    setInvoiceCurrent(item);
    setInvoicesBilling({ overcharging: false, tax: true });
  };

  const handleOvercharging = () => {
    setInvoiceCurrent(item);
    setInvoicesBilling({ overcharging: true, tax: false });
  };

  const handleRefreshDate = () => {
    const controlDate = convertDateToWithDash(new Date());
    editInvoice(token, item.id, { control_date: controlDate, control_by: username })
  };

  const handlePurchasePriceEdit = value => {
    let editInvoiceBody = { transporter_amount: value };
    if(Number(item.transporter_amount_old) === 0) {
      editInvoiceBody = { transporter_amount_old: item.transporter_amount, transporter_amount: value };
    }

    editInvoice(token, item.id, editInvoiceBody)
  };

  useEffect(() => {
    if(Object.keys(invoiceEditResponse).length && (invoiceEditResponse.id === item.id)) {
      setItem(invoiceEditResponse);
    }
  }, [invoiceEditResponse]);

  useEffect(() => {
    const timerID = setTimeout(() => {
      setCopyTitle(false);
    }, 3000);

    return () => clearTimeout(timerID);
  }, [copyTitle]);

  const isDisable = (item.number && ((item.number[0] === 'S') || (item.number[0] === 'T'))) ? true : false;

  const trackOrAir = item.tracking_number ? item.tracking_number : item.airwaybill;

  const customerName = item.customer_name ? item.customer_name : item.user_name ? item.user_name : item.other_customer;

  return (
    <TableRow className={classes.tableRow}>
      <TableCell className={classes.customerName}>
        {customerName}
      </TableCell>
      <TableCell>
        <div>
          {(item.tracking_number || item.airwaybill) ?
          <Tooltip
            title={copyTitle ? 'Copied' 
            : trackOrAir.replace(/<br\/>/g, " ")}
            placement="top"
          >
            <Button
                className={classes.trackingNumber}
                onClick={() => {
                    navigator.clipboard.writeText(trackOrAir.replace(/<br\/>/g, " "));
                    setCopyTitle(true);
            }}>
              <span className="button-text">
                {trackOrAir.replace(/<br\/>/g, " ")}
              </span>
            </Button>
          </Tooltip>
          : null }
        </div>
      </TableCell>
      <TableCell>
        {item.number}
      </TableCell>
      <TableCell>
        {convertDateToWithDots(item.date)}
      </TableCell>
      <TableCell>
        {item.is_ddp ? 'DDP' : 'DAP'}
      </TableCell>
      <TableCell>
        {item.payment_method}
      </TableCell>
      <TableCell>
        {(item.number.startsWith('T-') || item.number.startsWith('S-')) ? numberTwoDecimal(item.total_amount) : numberTwoDecimal(item.shipment_amount)}
      </TableCell>
      <TableCell>
        <div className={classes.editPrice}>
          <div>
            {Number(item.transporter_amount_old) > 0 ? <React.Fragment><span className={classes.oldAmount}>{numberTwoDecimal(item.transporter_amount_old)}</span><br/></React.Fragment> : null}
            {numberTwoDecimal(item.transporter_amount)}
          </div>
          <PurchasePriceEdit
            item={{ price: numberTwoDecimal(item.transporter_amount), trackingNumber: item.airwaybill }}
            handlePurchasePriceEdit={handlePurchasePriceEdit}
          />
        </div>
      </TableCell>
      <TableCell>
        <div className={classes.dateOfControl}>
          {item.control_date ? convertDateToWithDots(item.control_date) : '--.--.----'}
          <IconButton onClick={handleRefreshDate} size="large">
            <RefreshIcon />
          </IconButton>
        </div>
          {item.control_by ? <div className={classes.controlledBy}>
            <Tooltip
              title={item.control_by}
              placement="left"
            >
              <p>{item.control_by}</p>
            </Tooltip>
          </div> : ''}
      </TableCell>
      <TableCell>
        <PopUpPackage item={item} />
      </TableCell>
      <TableCell>
        <div className={`${classes.taxOvercharge} ${isDisable ? 'disabled-buttons' : ''}`}>
          <Tooltip
            title="Taxation"
            placement="top-start"
          >
            <IconButton disabled={isDisable} onClick={handleTaxation} size="large">
              <img src={taxationImg} alt="Taxation" />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Surfacturation"
            placement="top-start"
          >
            <IconButton disabled={isDisable} onClick={handleOvercharging} size="large">
              <img src={overchargingImg} alt="Surfacturation" />
            </IconButton>
          </Tooltip>
        </div>
      </TableCell>
    </TableRow>
  );
}

const stylesTablePag = makeStyles()((theme) => { return {
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}});

function TablePaginationActions(props) {
  const { classes } = stylesTablePag();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  
  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        size="large">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        size="large">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        size="large">
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        size="large">
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
count: PropTypes.number.isRequired,
onPageChange: PropTypes.func.isRequired,
page: PropTypes.number.isRequired,
rowsPerPage: PropTypes.number.isRequired,
};

const SorterButton = withStyles(Button, () => ({
  root: {
    color: '#fff',
    fontWeight: '700',
    textTransform: 'none',
  },
}));

function InvoicesBody(props) {
  const { classes } = useStyles();
  const { token } = useToken();
  const { invoicesMain,
    setInvoicesBilling,
    invoicesLoading,
    editInvoice,
    invoicesSort,
    setInvoicesSort,
    invoicesDateError,
    invoiceEditResponse,
    getInvoices,
    setInvoiceCurrent,
  } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);

  useEffect(() => {
    setPage(0);
  }, []);

  const countInvoises = invoicesMain.count;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, countInvoises - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getInvoices(token, newPage+1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function compareString(a, b) {
    let x, y;

    if(invoicesSort.byName && invoicesSort.isAsc && a.customer_name && b.customer_name) {
      x = a.customer_name.toLowerCase();
      y = b.customer_name.toLowerCase();
    }

    if(invoicesSort.byName && !invoicesSort.isAsc && a.customer_name && b.customer_name) {
      y = a.customer_name.toLowerCase();
      x = b.customer_name.toLowerCase();
    }

    if(invoicesSort.byControlledDate && invoicesSort.isAsc && a.control_date && b.control_date) {
      x = a.control_date;
      y = b.control_date;
    }

    if(invoicesSort.byControlledDate && !invoicesSort.isAsc && a.control_date && b.control_date) {
      y = a.control_date;
      x = b.control_date;
    }
    
    if(x < y) { return -1; }
    if(x > y) { return 1; }
    return 0;
  }

  const handleSorter = props => () => {
    if(invoicesSort[props]) {
      return setInvoicesSort({ ...invoicesSort, isAsc: !invoicesSort.isAsc });
    }

    if(props === 'byName') {
      return setInvoicesSort({ byControlledDate: false, byName: true, isAsc: true });
    }

    if(props === 'byControlledDate') {
      return setInvoicesSort({ byName: false, byControlledDate: true, isAsc: true });
    }
  };
  
  const emptyBody = (
    <div className={classes.emptyBody}>
      <Typography>(Empty)</Typography>
    </div>
  );

  const loadingBody = (
    <div className={classes.loading}>
      <CircularProgress color="primary" size="5em" />
      <Typography>Loading...</Typography>
    </div>
  );

  const notFoundBody = (
    <div className={classes.notFound}>
      <Typography><u>{invoicesMain.searchTerm}</u> is not found.</Typography>
    </div>
  );

  const noData = (
    <div className={classes.notFound}>
      <Typography>No any invoice. Try to select other dates...</Typography>
    </div>
  );

  const dateError = (
    <div className={classes.notFound}>
      <Typography>Dates cannot be empty.</Typography>
    </div>
  );

  if(invoicesLoading) {
    return loadingBody;
  }

  if(invoicesDateError) {
    return dateError;
  }

  if(!invoicesMain.invoices) {
    return emptyBody;
  }

  if(!invoicesMain.invoices.length && invoicesMain.searchTerm.length) {
    return notFoundBody;
  }

  if(!invoicesMain.invoices.length) {
    return noData;
  }

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow className={classes.tableHead}>
              <TableCell className={classes.tableFirstColumn}>                
                <SorterButton 
                  size="small"
                  onClick={handleSorter('byName')}
                >
                  Client&nbsp;
                  {(invoicesSort.byName && invoicesSort.isAsc) ? <ArrowDownwardIcon fontSize="small" />
                  : (invoicesSort.byName && !invoicesSort.isAsc) ? <ArrowUpwardIcon fontSize="small" />
                  : <ImportExportIcon fontSize="small" />}
                </SorterButton>
              </TableCell>
              <TableCell>Tracking</TableCell>
              <TableCell>N°facture</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Incoterm</TableCell>
              <TableCell>Paiement</TableCell>
              <TableCell className={classes.sellingPriceHeader}>Prix de vente transport HT</TableCell>
              <TableCell>Prix d’achat HT</TableCell>
              <TableCell>                
                <SorterButton 
                  size="small"
                  onClick={handleSorter('byControlledDate')}
                >
                  Date de contrôle&nbsp;
                  {(invoicesSort.byControlledDate && invoicesSort.isAsc) ? <ArrowDownwardIcon fontSize="small" />
                  : (invoicesSort.byControlledDate && !invoicesSort.isAsc) ? <ArrowUpwardIcon fontSize="small" />
                  : <ImportExportIcon fontSize="small" />}
                </SorterButton>
              </TableCell>
              <TableCell>Contenu</TableCell>
              <TableCell>Facturation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {invoicesMain.invoices.sort(compareString).map(item => <Row
              key={item.id}
              item={item}
              setInvoicesBilling={setInvoicesBilling}
              editInvoice={editInvoice}
              invoiceEditResponse={invoiceEditResponse}
              setInvoiceCurrent={setInvoiceCurrent}
            />)}
            {emptyRows > 0 && <TableRow style={{ height: 0 * emptyRows }} />}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[30]}
          colSpan={3}
          component="div"
          count={countInvoises}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
          inputProps: { 'aria-label': 'rows per page' },
          native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </TableContainer>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    invoicesMain: state.invoicesMain,
    invoicesLoading: state.invoicesLoading,
    invoicesSort: state.invoicesSort,
    invoicesDateError: state.invoicesDateError,
    invoiceEditResponse: state.invoiceEditResponse,
  };
};

export default connect(mapStateToProps, {
  setInvoicesBilling,
  editInvoice,
  setInvoicesSort,
  getInvoices,
  setInvoiceCurrent,
})(InvoicesBody);
