import Select from "react-select";
import { makeStyles } from "tss-react/mui";
import PropTypes from 'prop-types';

const options = [
	{ value: 0, label: 'En valeur' },
	{ value: 1, label: 'En pourcent' }
]

const useStyles = makeStyles()((theme) => {
	return {
		selector: {
			width: '100%',
		},
	}
});

function Display({ selected, setSelected }) {
	const { classes } = useStyles();

	return (
		<Select
			options={options}
			onChange={(e) => setSelected(e.value)}
			value={options.find((option) => option.value === selected)}
			className={classes.selector}
		/>
	);
}

Display.propTypes = {
	selected: PropTypes.number,
	setSelected: PropTypes.func
}

export default Display;