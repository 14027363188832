import Select from "react-select";
import makeAnimated from "react-select/animated";
import { makeStyles } from "tss-react/mui";
import PropTypes from 'prop-types';

export const monthsOptions = [
	{ value: 1, label: 'Janvier' },
	{ value: 2, label: 'Février' },
	{ value: 3, label: 'Mars' },
	{ value: 4, label: 'Avril' },
	{ value: 5, label: 'Mai' },
	{ value: 6, label: 'Juin' },
	{ value: 7, label: 'Juillet' },
	{ value: 8, label: 'Août' },
	{ value: 9, label: 'Septembre' },
	{ value: 10, label: 'Octobre' },
	{ value: 11, label: 'Novembre' },
	{ value: 12, label: 'Décembre' },
]

const useStyles = makeStyles()((theme) => { return {
	selector: {
		width : '100%',
	},
}});

const animatedComponents = makeAnimated();

function MonthsPeriod ({ selected, setSelected }) {
	const { classes } = useStyles();
	return (
		<Select
			options={monthsOptions}
			onChange={setSelected}
			isMulti
			value={selected}
			className={classes.selector}
			components={animatedComponents}
			closeMenuOnSelect={false}
			placeholder="Comparer des mois"
			
		/>
	);
}

MonthsPeriod.propTypes = {
	selected: PropTypes.arrayOf(PropTypes.object),
	setSelected: PropTypes.func,
}

export default MonthsPeriod;