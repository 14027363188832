import { useEffect, useState } from "react";
import StatisticsDestinationsControls from "./StatisticsDestinationsControls";
import StatisticsDestinationsTable from "./StatisticsDestinationsTable";
import PropTypes from 'prop-types';
import { Button, Stack } from "@mui/material";
import { fetchDestinationsData } from "./service/api";
import { monthsOptions } from "../fields/MonthsPeriod";
import { yearsOptions } from "../fields/YearsComparison";
import useToken from "../../login/useToken";

function StatisticsDestinations() {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const { token } = useToken();

	const [shipmentType, setShipmentType] = useState(0);
	const date = new Date().getMonth() - 1;
	const [monthsPeriod, setMonthsPeriod] = useState([monthsOptions[date]]);
	const [yearsComparison, setYearsComparison] = useState([yearsOptions[0]]);
	const [display, setDisplay] = useState(0);
	const [transporterId, setTransporterId] = useState(0);

	async function fetchData(signal) {
		const data = (await fetchDestinationsData(signal, token, shipmentType, [...monthsPeriod].map(i => i.value), [...yearsComparison].map(i => i.value), transporterId, display)).data.monthsData;
		if(signal.aborted) return;
		setData(data);
		// setLoading(false);
	}

	useEffect(() => {
		const abortController = new AbortController();
		(async () => {
			setLoading(true);
			fetchData(abortController.signal);
		})();  
		return () => {
			abortController.abort();
		};
	}, []);

	return (
		<>
			<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
				<StatisticsDestinationsControls
					shipmentType={shipmentType}
					monthsPeriod={monthsPeriod}
					yearsComparison={yearsComparison}
					transporterId={transporterId}
					display={display}
					setShipmentType={setShipmentType}
					setMonthsPeriod={setMonthsPeriod}
					setYearsComparison={setYearsComparison}
					setTransporterId={setTransporterId}
					setDisplay={setDisplay}
				/>
				<Button style={{whiteSpace: 'nowrap', height: '40px', marginTop: '16px', paddingLeft: '20px', paddingRight: '20px'}} variant="contained" color="primary" onClick={() => {
					setLoading(true);
					fetchData(new AbortController().signal);
				}}>Lancer la requête</Button>
			</Stack>
			{loading ? <div style={{textAlign:'center', marginTop:"50px"}}>Chargement... (Requête et réponse lourde, cela peut prendre 1 à 2 minutes)</div> : <StatisticsDestinationsTable data={data} isAllDestinations={transporterId === 0 ? true : false} isPercent={display === 1 ? true : false} />}
		</>
	);
}

StatisticsDestinations.propTypes = {
	data: PropTypes.array,
}

export default StatisticsDestinations;