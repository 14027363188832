import Select from "react-select";
import { makeStyles } from "tss-react/mui";
import PropTypes from 'prop-types';

const options = [
	{ value: 0, label: 'Tous'},
	{ value: 1, label: 'UPS'},
	{ value: 2, label: 'ARAMEX'},
	{ value: 3, label: 'FEDEX'},
	{ value: 4, label: 'DHL'},
	{ value: 5, label: 'TNT'},
	{ value: 6, label: 'FRETCARGO'},
	{ value: 7, label: 'SPRING'},
	{ value: 8, label: 'SPRINGGPA'},
	{ value: 9, label: 'UPS SAVER'},
	{ value: 10, label: 'UPS Worldwide Express'},
	{ value: 11, label: 'UPS Standard'},
	{ value: 12, label: 'UPS Express Plus'},
	{ value: 15, label: 'FEDEX_PRIORITY_OVERNIGHT'},
	{ value: 14, label: 'FEDEX_INTERNATIONAL_ECONOMY'},
	{ value: 16, label: 'SPRINGTNT'},
	{ value: 17, label: 'FEDEX_INTERNATIONAL_PRIORITY'},
	{ value: 18, label: 'TRANSNAT'},
	{ value: 19, label: 'FRETCARGO_Turkish'}
]

const useStyles = makeStyles()((theme) => {
	return {
		selector: {
			width: '100%',
		},
	}
});

function Pareto({ selected, setSelected, canPareto }) {
	const { classes } = useStyles();

	return (
		<Select
			options={options}
			onChange={(e) => setSelected(e.value)}
			value={options.find((option) => option.value === selected)}
			className={classes.selector}
		/>
	);
}

Pareto.propTypes = {
	selected: PropTypes.number,
	setSelected: PropTypes.func,
	canPareto: PropTypes.bool
}

export default Pareto;