import { makeStyles } from "tss-react/mui";
import PropTypes from 'prop-types';
import { FormControlLabel, Switch } from "@mui/material";

const useStyles = makeStyles()((theme) => {
	return {
		selector: {
		},
	}
});

function IsActivity({ selected, setSelected }) {
	const { classes } = useStyles();

	return (
		<FormControlLabel control={
			<Switch
				checked={selected}
				onChange={(e) => setSelected(e.target.checked)}
				className={classes.selector}
			/>
		} 
		label="Activité" />
	);
}

IsActivity.propTypes = {
	selected: PropTypes.bool,
	setSelected: PropTypes.func
}

export default IsActivity;