import { useEffect, useState } from "react";
import StatisticsFinanceControls from "./StatisticsFinanceControls";
import StatisticsFinanceTable from "./StatisticsFinanceTable";
import PropTypes from 'prop-types';
import { Stack } from "@mui/material";
import { fetchFinanceData } from "./service/api";
import { monthsOptions } from "../fields/MonthsPeriod";
import { yearsOptions } from "../fields/YearsComparison";
import useToken from "../../login/useToken";

function StatisticsFinance() {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const { token } = useToken();

	const [shipmentType, setShipmentType] = useState(0);
	const [monthsPeriod, setMonthsPeriod] = useState(monthsOptions);
	const [yearsComparison, setYearsComparison] = useState([yearsOptions[0], yearsOptions[1]]);
	const [pareto, setPareto] = useState(0);
	const [display, setDisplay] = useState(0);

	async function fetchData(signal) {
		const data = (await fetchFinanceData(signal, token, shipmentType, [...monthsPeriod].map(i => i.value), [...yearsComparison].map(i => i.value), pareto, display)).data.monthsData;
		if(signal.aborted) return;
		setData(data);
		setLoading(false);
	}

	useEffect(() => {
		const abortController = new AbortController();
		(async () => {
			setLoading(true);
			fetchData(abortController.signal);
		})();  
		return () => {
			abortController.abort();
		};
	}, [shipmentType, monthsPeriod, yearsComparison, pareto, display]);

	return (
		<>
			<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
				<StatisticsFinanceControls
					shipmentType={shipmentType}
					monthsPeriod={monthsPeriod}
					yearsComparison={yearsComparison}
					pareto={pareto}
					display={display}
					setShipmentType={setShipmentType}
					setMonthsPeriod={setMonthsPeriod}
					setYearsComparison={setYearsComparison}
					setPareto={setPareto}
					setDisplay={setDisplay}
				/>
			</Stack>
			{loading ? <div style={{textAlign:'center', marginTop:"50px"}}>Chargement...</div> : <StatisticsFinanceTable data={data} pareto={pareto} isPercent={display === 1 ? true : false} />}
		</>
	);
}

StatisticsFinance.propTypes = {
	data: PropTypes.array,
}

export default StatisticsFinance;